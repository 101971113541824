<template>
  <div>
    <a-modal width="900px" :visible="visible" title="Hizmeti Düzenle" @ok="closeModal" @cancel="closeModal"
             style="top: 15px">

      <a-form :model="generalForm" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-divider>Genel Bilgiler</a-divider>

        <a-form-item label="Hizmet Adı">
          <a-input :value="service.name" :readonly="true"/>
        </a-form-item>

        <a-form-item label="Durumu">
          <a-switch v-model:checked="generalForm.status"/>
        </a-form-item>

        <a-form-item label="Ödeme Döngüsü">
          <a-radio-group v-model:value="generalForm.paymentLoop">
            <a-radio value="monthly">Aylık</a-radio>
            <a-radio value="yearly">Yıllık</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Yenilenme Sıklığı">
          <a-radio-group v-model:value="generalForm.updateLoop" :disabled="true">
            <a-radio value="4hours">4 Saat</a-radio>
            <a-radio value="2hours">2 Saat</a-radio>
            <a-radio value="1hours">1 Saat</a-radio>
            <a-radio value="30minutes">30 Dakika</a-radio>
            <a-radio value="15minutes">15 Dakika</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="Servis Ücreti">
          <a-input :value="service.price + '₺'" :disabled="true"/>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 16, offset: 4 }">
          <a-button block type="primary" @click="generalSubmit">Kaydet</a-button>
        </a-form-item>
      </a-form>

      <a-form :model="paymentForm" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-divider>Ödeme Ekleme</a-divider>

        <a-form-item label="Ödeme Nedeni">
          <a-select v-model:value="paymentForm.paymentReason" placeholder="lütfen bir neden seçin">
            <a-select-option value="normal">Normal Ödeme</a-select-option>
            <a-select-option value="between">Ara Ödeme</a-select-option>
            <a-select-option value="all">Toplu Ödeme</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Ödeme Tarihi" v-if="typeof paymentForm.paymentReason !== 'undefined'">
          <a-date-picker
              v-model:value="paymentForm.paymentDate"
              type="date"
              placeholder="Tarih seçin"
              style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="Ödeme Miktarı" v-if="typeof paymentForm.paymentDate !== 'undefined'">
          <a-input
              v-model:value="paymentForm.paymentAmount"
              placeholder="Ödeme miktarını girin"
              type="number"
              style="width: 100%"
          />
        </a-form-item>

        <a-form-item label="Ödeme Dekontu" v-if="typeof paymentForm.paymentAmount !== 'undefined'">
          <UploadBtn :customer="this.service.cOID" :customer-file-list="uploadFileList"
                     @fileListUpdate="uploadFileList = $event"/>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 16, offset: 4 }">
          <a-button block type="primary" @click="paymentSubmit"
                    :disabled="!(uploadFileList.length !== 0)">Kaydet
          </a-button>
        </a-form-item>
      </a-form>

      <a-form :model="timeForm" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-divider>Süre Uzatma</a-divider>

        <a-form-item label="Bitiş Tarihi">
          <a-input :value="service.endTime ? unixToDate(service.endTime) : 'daha önce bitiş tarihi eklenmemiş'"
                   :disabled="true"/>
        </a-form-item>

        <a-form-item label="Süre Uzatma Nedeni">
          <a-select v-model:value="timeForm.timeReason" placeholder="lütfen bir neden seçin">
            <a-select-option value="payment">Ödeme</a-select-option>
            <a-select-option value="free">Ücretsiz Uzatma</a-select-option>
            <a-select-option value="other">Diğer ( kendim belirteceğim )</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="Diğer (Açıklayın)" v-if="timeForm.timeReason === 'other'">
          <a-input v-model:value="timeForm.timeReasonDesc"/>
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 16, offset: 4 }" v-if="typeof timeForm.timeReason !== 'undefined'">
          <a-alert
              message="Uyarı"
              :description="'Müşterinin bu servisi ' + (service.options.paymentLoop === 'monthly' ? 'Aylık' : 'Yıllık') + ' olarak yenilenmektedir. Bu servisin önerilen bitiş tarihi: ' + unixToDate(suggestedDate) "
              type="warning"
              show-icon
          />
        </a-form-item>


        <a-form-item label="Bitiş Tarihi" v-if="typeof timeForm.timeReason !== 'undefined'">
          <a-date-picker
              v-model:value="timeForm.endTime"
              type="date"
              placeholder="Tarih seçin"
              style="width: 100%"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 16, offset: 4 }">
          <a-button block type="primary" @click="timeSubmit"
                    :disabled="!(typeof timeForm.endTime !== 'undefined' && typeof timeForm.timeReason !== 'undefined')">
            Kaydet
          </a-button>
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button @click="closeModal">Kapat</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {defineComponent, reactive} from 'vue';
import {unixToDate} from "@/helpers/unixToDate";
import instance from "@/http/axios";
import {message} from "ant-design-vue";
import UploadBtn from "@/components/UploadBtn";
import {socket} from "@/http/socket";
import uuid from "uuid";
import {sendNotification} from "@/helpers/sendNotification";

export default defineComponent({
  data() {
    return {
      uploadFileList: [],
      suggestedDate: undefined
    }
  },
  setup: function () {
    const generalForm = reactive({
      status: false,
      paymentLoop: '',
      updateLoop: ''
    });

    const paymentForm = reactive({
      paymentDate: undefined,
      paymentReason: undefined,
      paymentAmount: undefined,
    })

    const timeForm = reactive({
      endTime: undefined,
      timeReason: undefined,
      timeReasonDesc: '',
    })

    return {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
      generalForm, paymentForm, timeForm,
      unixToDate
    };
  },
  components: {
    UploadBtn
  },
  props: ['visible', 'service'],
  methods: {
    generalSubmit() {
      let form = new FormData();
      form.append('key', this.service.key)
      form.append('cKey', this.service.cKey)
      form.append('status', this.generalForm.status);
      form.append('paymentLoop', this.generalForm.paymentLoop)

      instance.post('/customer/catalog/service/updateGeneralInfo', form).then(response => {
        if (response.data.code === 200) {
          message.success(response.data.msg)
          sendNotification('system', 'user', this.service.aID, this.generalForm.status ? "success" : "error", 'Servisin Durumu Güncellendi!', "Temsilcisi olduğunuz " + this.service.cNAME + " isimli müşterinin " + this.service.name + " isimli servisi " + this.$store.getters.authUserInfo.name + " tarafından durumu " + (this.generalForm.status ? 'aktif' : 'pasif') + " hale getirildi!")
        } else {
          message.error(response.data.msg)
        }

      })

      this.$emit('tableUpdate', true)
    },
    paymentSubmit() {
      let form = new FormData();
      form.append('key', this.service.key)
      form.append('cKey', this.service.cKey)
      form.append('paymentReason', this.paymentForm.paymentReason)
      form.append('paymentDate', new Date(this.paymentForm.paymentDate._d).getTime())
      form.append('paymentAmount', this.paymentForm.paymentAmount)
      form.append('fileList', JSON.stringify(this.uploadFileList))
      form.append('serviceName', this.service.name)

      instance.post('/customer/catalog/service/addPaymentInfo', form).then(response => {
        if (response.data.code === 200) {
          message.success(response.data.msg)
          sendNotification('system', 'user', this.service.aID, 'success', 'Servise Ödeme Eklendi', "Temsilcisi olduğunuz " + this.service.cNAME + " isimli müşterinin " + this.service.name + " isimli servisine " + this.$store.getters.authUserInfo.name + " tarafından " + (this.paymentForm.paymentReason === "all" ? "Toplu Ödeme" : this.paymentForm.paymentReason === "between" ? "Ara Ödeme" : "Normal Ödeme") + " nedeniyle " + this.paymentForm.paymentAmount + " tutarında ödeme eklendi!")
        } else {
          message.error(response.data.msg)
        }

        /* formu sıfırla */
        this.paymentForm.paymentReason = undefined
        this.paymentForm.paymentDate = undefined
        this.paymentForm.paymentAmount = undefined
        this.uploadFileList = []
        /* formu sıfırla */

        this.$emit('tableUpdate', true)
      })


    },
    timeSubmit: function () {
      let form = new FormData();
      let endDate = new Date(this.timeForm.endTime);
      if (endDate.getDay() === 0 || endDate.getDay() === 6) endDate.setDate(endDate.getDate() + 2)
      form.append('key', this.service.key)
      form.append('cKey', this.service.cKey)
      form.append('endTime', endDate.getTime())
      form.append('serviceName', this.service.name)
      form.append('timeReason', this.timeForm.timeReason)
      if (this.timeForm.timeReason === "other")
        form.append('timeReasonDesc', this.timeForm.timeReasonDesc)
      instance.post('/customer/catalog/service/updateTimeInfo', form).then(response => {
        if (response.data.code === 200) {
          message.success(response.data.msg)
          sendNotification('system', 'user', this.service.aID, 'info', 'Servisin Süresi Değiştirildi!', "Temsilcisi olduğunuz " + this.service.cNAME + " isimli müşterinin " + this.service.name + " isimli servisi " + this.$store.getters.authUserInfo.name + " tarafından " + (this.timeForm.timeReason === "payment" ? "Ödeme" : this.timeForm.timeReason === "free" ? "Ücretsiz Uzatma" : this.timeForm.timeReasonDesc) + " nedeniyle " + unixToDate(this.timeForm.endTime) + " tarihine kadar uzatıldı!")
        } else {
          message.error(response.data.msg)
        }

        /* form cleaned */
        this.timeForm.timeReason = undefined
        this.timeForm.endTime = undefined
        this.timeForm.timeReasonDesc = ''
        this.$emit('tableUpdate', true)
      })
    },
    closeModal() {
      this.$emit('closeModal', false)
    }
  },
  watch: {
    visible() {
      if (this.visible) {
        /* formData initalized */
        this.generalForm.status = this.service.status
        this.generalForm.paymentLoop = this.service.options.paymentLoop
        this.generalForm.updateLoop = this.service.options.updateLoop

        /* Önerilen Uzatma Tarihi */
        if (this.service.endTime !== null) {
          let date = new Date(this.service.endTime);
          if (this.service.options.paymentLoop === "yearly")
            this.suggestedDate = date.setFullYear(date.getFullYear() + 1)
          else
            this.suggestedDate = date.setMonth(date.getMonth() + 1)
        } else {
          let date = new Date();
          if (this.service.options.paymentLoop === "yearly")
            this.suggestedDate = date.setFullYear(date.getFullYear() + 1)
          else
            this.suggestedDate = date.setMonth(date.getMonth() + 1)
        }

        this.timeForm.endTime = new Date(this.suggestedDate).toDateString()
        /* Önerilen Uzatma Tarihi */

      }
    }

  }
});
</script>